var ClipboardCompare = (function ($)
{
	var ls = localStorage;
	var _key = "clipboard_compare";
	
	var _core = {
		init: function()
		{
			var items = ls.getItem( _key );
			
			if( items == null )
				ls.setItem( _key, JSON.stringify([]));
			
		},
		
		click: function()
		{
			var data = $( this ).data('item');
			
			var items = JSON.parse( ls.getItem( _key ) );
			var position = -1;
		
			$.each( items, function( index, value ) {
				if( data.id == value.id )
					position = index;
			});
		
		
			if( position != -1 ) 
				items.splice( position, 1 );
			else if( items.length == 4 )
				alert('Max 4');
			else
				items.push( data );
				
			
			ls.setItem( _key, JSON.stringify(items));
			
			_view.build();
			_view.btn();
		},
		
		count: function()
		{
			var items = JSON.parse( ls.getItem( _key ) );

			return items.length;
		}
	};
	
	var _view = {
			
		toggle: function()
		{
			$('.offers-clipboard').toggleClass('card-show');
		},
		
		count: function()
		{
			$('.compare-count').text( _core.count );
		},
		
		
		btn: function ()
		{
			$('.compare').each( function() {
				var $this = $( this );
				var item = $this.data('item');
				var items = JSON.parse( ls.getItem( _key ) );
				
				$.each( items, function( index, value ) {
					if( item.id == value.id ) {
						$this.addClass('added');
						return false;
					} else {
						$this.removeClass('added');
					}
						
				});
				
			});
		},
		
		build: function()
		{
			var items = JSON.parse( ls.getItem( _key ) );
			var view = $('.compare-list');
			
// 			console.log( items );
			
			view.empty();
			
			for( var i = 1; i <= 4; i++ )
			{
				var flag = (items.length >= i);
				
				if( flag )
					var value = items[ i - 1 ];
				else
					var value = {
						title: '---',
						price: '-',
						id: ''
					}
				
				var item = $('<li></li>');
				
				var title = $('<span />').addClass('title').text( value.title ).appendTo( item );
				var price = $('<span />').addClass('price').text( value.price ).appendTo( item );
				var remove = $('<span />').addClass('remove').html( '<i class="fa fa-times" aria-hidden="true"></i>' )
					.attr({
						'data-item': JSON.stringify( value )
					})
					.click( _core.click );
					
				if( flag )	
					remove.appendTo( item );
				
				var link = $('<input />').attr({
					name: 'compare[]',
					value: value.id,
					type: 'hidden'
				}).appendTo( item );
				
				view.append( item );
			}
		
			_view.count();
		},
		
		init: function()
		{
			_view.build();
		}
		
	}
	
	var _public = {
		
		ready: function()
		{
// 			$('.compare').click( _core.click );
			$( document ).on( 'click', '.compare', _core.click );
			
			
			$('.card-toggle').click( _view.toggle );
			
			
		},
		
		btn: function()
		{
			_view.btn();
		},
		
		init: function()
		{	
			if( Modernizr.localstorage )
			{
				_core.init();
				
				$( document ).ready( _view.init );
				$( document ).ready( _public.ready );
			}
		}
	};
	
	return _public;
})(jQuery);

ClipboardCompare.init();







var SearchCars = (function ($)
{
	var _offset = 0;
	var _limit = 600;
	var _current = 0;
	var _filters = {};
	
	var _private = {
		
		ajax: function ()
		{
			var _action = {
				action: 'get_cars'
			};
			
			var _data = $.extend( _action, _filters );
			
			$.ajax({
				url: v.ajaxurl,
				data: _data,
				success: function( data )
				{
					if( _current == 1 )
						$('.row-items').empty();
					
					$('.row-items').append( data );
					ClipboardCompare.btn();
				},
				beforeSend: function()
				{
					$('.load-animation').toggleClass('hidden');
				},
				complete: function()
				{
					$('.load-animation').toggleClass('hidden');
				}
			});
		},
		
		submit: function( event )
		{
			event.preventDefault();
			
			_private.loadNew();
		},
		
		loadNew: function( )
		{			
			var filters = $('#cars-filters').serializeArray();

			_filters = {};
			_current = 0;
			_offset = 0;

			$.each( filters, function( key, value )
			{
				_filters[ value.name ] = value.value;
			});
			
// 			_filters.limit = _limit;
			
// 			history.pushState({id: 'new filters'}, '', '?'+ $('#cars-filters').serialize() );
			
			_private.loadMore();
		},
		
		loadMore: function()
		{		
			_offset = _current * _limit;
			_current++;
			
			
			_filters.offset = _offset;
			
			_private.ajax();
			

		},
		
		make_model: function ()
		{
			var $selected = $( this ).val();
			var $model = $('#cars-filters').find("select[name='model']");
			
			if( $model.find(":selected").data('group') != $selected )
				$model.val('');
									
			$model.find('option[data-group]').each(function () {
				$this = $( this );
				$group = $this.data('group');
				
				if( $group == $selected )
					$this.show();
				else
					$this.hide();
				
			});
		},
		

		
	};
	
	var _init = {
		init: function()
		{	
			_private.loadNew();
			
			var $filters = $('#cars-filters');
			$filters.submit( _private.submit );
			$filters.find("select[name='make']").change( _private.make_model ).change();
		},
		simple: function()
		{		
			var $filters = $('#cars-filters');
			
			$filters.find("select[name='make']").change( _private.make_model ).change();
		},	
	};
	
	var _public = {
		

		init: function()
		{	
			$( document ).ready( _init.init );
		},
		simple: function()
		{	
			$( document ).ready( _init.simple );
		},
	};
	
	return _public;
	
})(jQuery);









