new WOW().init();
var dates = {};
(function($) {


  $(document).ready(function() {
	  
	  /*** ZMIANA ROZMIARU FONU ***/
		var html = $('html');
		var allChangeText = $('.changeText a');
		
		$('.changeText a:eq(0)').on('click', function(){
		
			html.removeClass('smaller').addClass('bigger');
			allChangeText.removeClass('active');
			$(this).addClass('active');
		});
		
		$('.changeText a:eq(1)').on('click', function(){
			html.removeClass('bigger smaller');
			allChangeText.removeClass('active');
			$(this).addClass('active');
		});
		
		$('.changeText a:eq(2)').on('click', function(){
			html.removeClass('bigger').addClass('smaller');
			allChangeText.removeClass('active');
			$(this).addClass('active');
		});

	 
        $('img.svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                
                               
                var $svg = jQuery(data).find('svg');
				$svg = $svg.remove('defs style')
                
//                 console.log( $svg );
                

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
               

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        }); // img.svg
        $('.home-slider').flexslider({
			animation: "fade",
			slideshowSpeed: 4000, 
			touch: true,
			animationSpeed: 1200,
			pauseOnHover: false,
			controlsContainer: $(".nav-container"),
			 
		  });  
		  
		$('.back-to-top').click(function() {
			
			jQuery('html,body').animate({
	          scrollTop: 0
	        }, 1000);
	        
	        return false;
		});
        
		$('#contactModal').on('show.bs.modal', function (event)
		{
			var button = $(event.relatedTarget);
			var modal = $(this);
			
			var info = button.data('info');
			
			modal.find('.photo').attr({
				'src': info.photo,
				'alt': info.title
			});
			modal.find('.title').text( info.title );
			modal.find('.position').text( info.position );
			
			var phone = modal.find('.phone');
			phone.find('a').attr('href', 'tel:'+ info.phone );
			phone.find('span').text( info.phone )
			
			modal.find('.to').val( info.to );
			
		});
		$(".navbar-toggle").click(function() {
			if($(this).hasClass( "open" )) {
				$(this).removeClass( "open" );
			} else {
				$(this).addClass( "open" );
			}
		});
		// $('.navbar-nav .menu-item a').click(function () {
			
			// var $this = $(this).parent();

			
			// if( !( $this.hasClass('menu-item-13') || $this.hasClass('menu-item-23') ) )
				// $('.dropdown-container').removeClass('show');
		// });
        
/*
		$(document).on("click", function(e) {
			if ( $(e.target).is(".dropdown-container, .menu-item-13, .menu-item-23") === false)
			{
				$('.dropdown-container').removeClass('show');
			}
		});
*/
        
        
        // na szybko
        
        // $('.navbar-nav .menu-item-13 a').click(function (event) {
	        // event.preventDefault();
			// $('.dropdown-container.ddm-7').toggleClass('show');
        // });
        
        // $('.navbar-nav .menu-item-23 a').click(function (event) {
	        // event.preventDefault();
			// $('.dropdown-container.ddm-16').toggleClass('show');
        // });
        
        // $( document ).on('click', function( event )
        // {
	        // var clickover = $(event.target);
	        // var _opened = $(".dropdown-container").hasClass("show");
	        
	        // if ( _opened === true && !clickover.hasClass("dropdown-toggle") )
	        // {
	           // $(".dropdown-container").removeClass("show");
			// }
        // });
        
        $('.button-alfabet').click(function(e) {
			 e.preventDefault(); 
			var $alpha = $(this).data("alpha");
			 
			 $("html, body").animate({
		        scrollTop: $( '.alpha_'+$alpha).offset().top
		    }, 300);
	        
        });
        
        $('.scroll-top').click(function() {
	        $("html, body").animate({
		        scrollTop: 0
		    }, 300);
        });
        
        $('.print').click(function() {
	       window.print(); 
        });
        
		$.datepicker.regional["pl"] = {
			closeText: "Zamknij",
			prevText: "<Poprzedni",
			nextText: "Następny>",
			currentText: "Dziś",
			monthNames: ["Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec",
			"Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień"],
			monthNamesShort: ["Sty","Lu","Mar","Kw","Maj","Cze",
			"Lip","Sie","Wrz","Pa","Lis","Gru"],
			dayNames: ["Niedziela","Poniedziałek","Wtorek","Środa","Czwartek","Piątek","Sobota"],
			dayNamesShort: ["Nie","Pn","Wt","Śr","Czw","Pt","So"],
			dayNamesMin: ["N","Pn","Wt","Śr","Cz","Pt","So"],
			weekHeader: "Tydz",
			dateFormat: "yy-mm-dd",
			minDate: 0,
			firstDay: 1,
			isRTL: false,
			showMonthAfterYear: false,
			yearSuffix: ""};
			
			
		if(current_lang == "pl") {
			$.datepicker.setDefaults($.datepicker.regional["pl"]);
		}
			
		if(date_events != undefined)
		for(var i = 0; i < date_events.length; i++)
		{
			 addNextDays(new Date(date_events[i].date_start), new Date(date_events[i].date_end));
			  
		} 
	 
			$("#datepicker").datepicker( {
				beforeShowDay: function(date) { 
				
						var day = date.getDay();
							if (day == 1) {
								return [false, ""]
							} else {
								
								if(block_dates.length > 0)
								{
									for(var t = 0; t < block_dates.length; t++)
									{ 
										var sD = new Date( block_dates[t].data);  
										  
										if(date.getYear() == sD.getYear() && date.getMonth() ==  sD.getMonth() && date.getDate() == sD.getDate() ) {
											return [false, ""]
										}  
									}
									return [true, ""];
								} else {
									return [true, ""];
								}								
								
								
						}
				
				  var hlText = dates[date.getTime()];
				  return (hlText)?[true, "Highlighted", 'a']: [true, '', ''];
				},
				onSelect: function (date) {
					var $html = "";  
					 var $min = 0;
					var current = new Date(date); 
					 
					for(var i = 0; i < date_events.length; i++)
					{
						var sD = new Date(date_events[i].date_start);
						var eD = new Date(date_events[i].date_end);
						 
						if(sD.getTime() <= current.getTime() && eD.getTime() >= current.getTime())
						{
							var desc = date_events[i].short_description.replace("<br />", "");
							desc = desc.replace("<br>", "");
							
							$html += "<div class='popup-item'><a href='"+ date_events[i].link +"' ><h4>" + date_events[i].title + "</h4>" + desc + "</a><p><hr /></div>";
							$min++;
						}
						
					}
					if($min > 0) {
						$(".date-more-info").css("display", "block");
						$(".date-more-info .content").html($html);
					} else {
						$(".date-more-info").css("display", "none");
						$(".date-more-info .content").html($html);
					}
				}
			});
		 
		
		 
		$(".date-more-info .close").click(function(){
			$(".date-more-info").css("display", "none");
			var ui = $("#datepicker").find(".ui-state-active");
			 
			ui.removeClass("ui-state-active");
		});
		
/*
		$(".search_button").click(function(e) {
			var $field = $(".search-field");
			if($field.css('opacity') == "0") {
				$field.css('opacity', "1");
				$field.css('width', "100px");
				e.preventDefault();
			}
			if($field.val() == "")
			{
				e.preventDefault();
			}
		});
*/
        
		
		// if( $( window ).width() > 767 )
			// $('.navbar-nav .dropdown-toggle').attr('data-toggle', '');
		// else
			// $('.navbar-nav .dropdown-toggle').attr('data-toggle', 'dropdown');
			
			
			
		$('.switch-view').click(function () {
			var $view = $( this ).data('view');
			
			$('.row-items').removeClass('grid').removeClass('list');
			$('.row-items').addClass( $view );
		})
		
			
		
		/*** KONTRAST ***/
		if(localStorage.getItem('contrast') === null) {
			localStorage.setItem('contrast', 'false');
		} 
		
		var changeContrast = function(){
			if(localStorage.getItem('contrast') == 'true') $('body').addClass('contrast');
			else if(localStorage.getItem('contrast') == 'false') $('body').removeClass('contrast');
		};
		
		changeContrast();
		
		$('.eye').on('click', function(){
			if(localStorage.getItem('contrast') == 'true') localStorage.setItem('contrast', 'false');
			else if(localStorage.getItem('contrast') == 'false') localStorage.setItem('contrast', 'true');
			
			changeContrast();
		});
	  
	  
	  
		// $('.navbar-collapse').on('show.bs.collapse', function () {
			// $('.offers-clipboard').toggleClass('xs-show');
		// });
		
		// $('.navbar-collapse').on('hide.bs.collapse', function () {
			// $('.offers-clipboard').toggleClass('xs-show');
		// });
  });
    
  	
	
  
	// $( window ).resize(function() {
		
		// if( $( window ).width() > 767 )
			// $('.navbar-nav .dropdown-toggle').attr('data-toggle', '');
		// else
			// $('.navbar-nav .dropdown-toggle').attr('data-toggle', 'dropdown');
		
	// });
	
	
	var $container = $('.masonry-container');
	$container.masonry({
		columnWidth: '.item',
		itemSelector: '.item'
	});
    


})(jQuery);





var Slider = (function($)
{
	var _public = {
		
		slider: function()
		{
			$('.flexslider').flexslider({
				controlNav: !false,
				directionNav: false,  
			});
			
			
		},
		
		init: function()
		{
			$( document ).ready( _public.slider );
		}
	}
	
	
	return _public;
}(jQuery)).init();

function getMonthNumber($month) {
	
	if($month == "Styczeń") {
		return "01"
	}
	if($month == "Luty") {
		return "02"
	}
	if($month == "Marzec") {
		return "03"
	}
	if($month == "Kwiecień") {
		return "04"
	}
	if($month == "Maj") {
		return "05"
	}
	if($month == "Czerwiec") {
		return "06"
	}
	if($month == "Lipiec") {
		return "07"
	}
	if($month == "Sierpień") {
		return "08"
	}
	if($month == "Wrzesień") {
		return "09"
	}
	if($month == "Październik") {
		return "10"
	}
	if($month == "Listopad") {
		return "11"
	}
	if($month == "Grudzień") {
		return "12"
	}
 
}

Date.prototype.addDays = function(days)
{
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}

function addNextDays(date_start, date_end)
{
	dates[date_start.getTime()]= true;
	if( date_start < date_end)
	{
		addNextDays(date_start.addDays(1), date_end);
	}
	  
}		
